import React from "react";
import CssBaseline from "@mui/material/CssBaseline";

import GlobalStyles from "@mui/material/GlobalStyles";

import IMAGES from "../../constants/images";

import Footer from "../../components/website/Footer";
import { NavStrip } from "../../components/website/Navbar";
import { connect } from "react-redux";

import { Avatar, Box, Container, Grid, Stack, Typography } from "@mui/material";
import BreadCrumb from "../../components/website/BreadCrumb";
import { COLORS, FONTS } from "../../constants";
import CardView from "../../components/website/CardView";

const AboutUs = (props) => {
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <NavStrip />

      <BreadCrumb page="About Us" from="Home" fromLink="/home" />

      <Box className="container" sx={{ mt: 10 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <CardView
              title={"Trust"}
              description="We protect shoppers and brands from fraud so that you can shop and sell with confidence"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <CardView
              title={"Access"}
              description="We protect shoppers and brands from fraud so that you can shop and sell with confidence"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <CardView
              title={"Community"}
              description="Life should be easy. We make the shopping and selling process simple and hassle-free for our users"
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ backgroundColor: COLORS.other, mt: 10 }}>
        <Grid container sx={{}}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            sx={{
              padding: 5,
            }}
          >
            <Typography
              sx={{
                fontFamily: FONTS.subtitle,
                fontSize: 18,
                textAlign: "justify",
              }}
            >
              <br /> As someone who suffered from alopecia (hair loss) during my
              teenage years, I know how much hair can impact self-esteem. During
              that time, I wanted to find the perfect hair that could help
              rebuild my confidence, but I found it overwhelming, frustrating
              and difficult to find hair that fit both my needs and budget.
            </Typography>
            <Typography
              sx={{
                fontFamily: FONTS.subtitle,
                fontSize: 18,
                textAlign: "justify",
              }}
            >
              <br />
              As I became an adult, I carried that experience with me and
              wondered how I could help others going through similar struggles.
              After college, I started my career in tech and began learning how
              to build apps. One day, I realized that I could solve the problem
              -- the hair problem.
            </Typography>
            <Typography
              sx={{
                fontFamily: FONTS.subtitle,
                fontSize: 18,
                textAlign: "justify",
              }}
            ></Typography>
            <Typography
              sx={{
                fontFamily: FONTS.subtitle,
                fontSize: 18,
                textAlign: "justify",
              }}
            >
              <br />I created Hair Scout. With Hair Scout, anyone can find their
              perfect hair. Hair Scout brings trust, access, and community to
              everyone; because quality hair should be accessible to all.
              Through technology and community we will continue to dedicate our
              energy to ensuring that good hair days are infinite.
            </Typography>

            <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
              <Avatar
                alt="img"
                src={IMAGES.dummy}
                size="large"
                sx={{
                  display: "flex",
                  alignSelf: "center",
                  textAlign: "center",
                  mt: 7,
                  width: 56,
                  height: 56,
                }}
              />
            </Stack>
            <Typography
              sx={{
                fontFamily: FONTS.title,
                textAlign: "center",
                fontSize: 16,
                mt: 2,
              }}
            >
              Cree Robinson
            </Typography>
            <Typography
              sx={{
                fontFamily: FONTS.subtitle,
                textAlign: "center",
                fontSize: 14,
              }}
            >
              Hair Scout Founder
            </Typography>
          </Grid>
          <Grid item sx={12} sm={12} md={6} lg={6}>
            <img style={{ height: "100%" }} src={IMAGES.about_bg} alt="" />
          </Grid>
        </Grid>
      </Box>

      {/* <Box sx={{ mt: 10 }}>
        <section class="clients spad ">
          <div class="container ">
            <div class="row">
              <div class="col-lg-12">
                <div class="section-title">
                  <span>Partner</span>
                  <h2>Happy Clients</h2>
                </div>
              </div>
            </div>
            <div class="row ">
              <div class="col-lg-3 col-md-4 col-sm-4 col-6 d-flex justify-content-center">
                <a href="#" class="client__item">
                  <img src={require("./img/clients/client-1.png")} alt="" />
                </a>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-4 col-6 d-flex justify-content-center">
                <a href="#" class="client__item">
                  <img src={require("./img/clients/client-2.png")} alt="" />
                </a>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-4 col-6 d-flex justify-content-center">
                <a href="#" class="client__item">
                  <img src={require("./img/clients/client-3.png")} alt="" />
                </a>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-4 col-6 d-flex justify-content-center">
                <a href="#" class="client__item">
                  <img src={require("./img/clients/client-4.png")} alt="" />
                </a>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-4 col-6 d-flex justify-content-center">
                <a href="#" class="client__item">
                  <img src={require("./img/clients/client-5.png")} alt="" />
                </a>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-4 col-6 d-flex justify-content-center">
                <a href="#" class="client__item">
                  <img src={require("./img/clients/client-6.png")} alt="" />
                </a>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-4 col-6 d-flex justify-content-center">
                <a href="#" class="client__item">
                  <img src={require("./img/clients/client-7.png")} alt="" />
                </a>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-4 col-6 d-flex justify-content-center">
                <a href="#" class="client__item">
                  <img src={require("./img/clients/client-8.png")} alt="" />
                </a>
              </div>
            </div>
          </div>
        </section>
      </Box> */}
      <Footer />
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
