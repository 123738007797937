import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { COLORS, FONTS } from "../../constants/theme";
import Footer from "../../components/website/Footer";
import { NavStrip } from "../../components/website/Navbar";
import { connect } from "react-redux";
import BreadCrumb from "../../components/website/BreadCrumb";
import {Container } from "react-bootstrap";
import {Box, Button,FormControl,Grid,InputLabel, MenuItem,Rating,Select,Stack,Typography,} from "@mui/material";
import {RatingStars,} from "../../components";
import { useLocation, useNavigate} from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_URL } from "../../apis";
import { SHOPPING_CART } from "../../apis";
import { handleGetApi,handlePostApi } from "../../services";
import LoadingModal from "../../components/modals/LoadingModal";
import { useSelector,useDispatch } from "react-redux";
import { addItem,updateItem } from "../../redux/Slice/CartSlice";

const Details = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const detailsproduct = location.state;
  const cartItems = useSelector((state) => state.cart.items);
  const [allProducts, setAllProducts] = useState([detailsproduct]);
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [modifiedProducts, setModifiedProducts] = useState([]);

  const handleClickProducts = () => {
    navigate("/categories-list");
  };

  // useEffect(() => {
  //   getproductss();
  // }, []);

  // const getproductss = async () => {
  //   let url = `${BASE_URL.baseUrl}customer/products/${parentdid}`;

  //   try {
  //     const response = await handleGetApi(url, token);
  //     setAllProducts(response?.data?.products);
  //     setLoading(false);
  //   } catch (err) {
  //     toast.error("Something Went Wrong");
  //     setLoading(false);
  //   }
  // };


  const handleChange = (e, productId) => {
    const { name, value } = e.target;
    const modifiedProduct = allProducts.find(product => product.id === productId);
    const updatedProduct = {
      ...modifiedProduct,
      [name]: value
    };
    setModifiedProducts(prevState => ({
      ...prevState,
      [productId]: {
        ...prevState[productId],
        ...updatedProduct 
      }
    }));
  };
  const handleIncreaseQuantity = () => {
    let qty = quantity + 1;
    setQuantity(qty);
  };
  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      let qty = quantity - 1;
      setQuantity(qty);
    }
  };
  const handleAddToCart = async (product) => {
    const data = modifiedProducts[product.id];
    if (!data || Object.keys(data).length === 0) {
      toast.error("Please select color and length");
    } else if (!data.length || !data.color) {
      let errorMessage = "";
      if (!data.length) {
        errorMessage += "length ";
      }
      if (!data.color) {
        errorMessage += "color ";
      }
      toast.error("Error: Please select " + errorMessage + "to continue.");
    } else if (quantity > data.length.stock || quantity > data.color.stock) {
      toast.error("Error: Quantity exceeds stock. Please decrease quantity.");
    } else {
      const cartItem = {
        ...product,
        quantity: quantity,
        shopping_cart_type: "ShoppingCart",
        product_attributes: [
          {
            id: data?.length?.length_attribute_id,
            value: data?.length?.length_value_id
          },
          {
            id: data?.color?.color_attribute_id,
            value: data?.color?.color_value_id
          }
        ]
      };
  
      const existingItem = cartItems.find(item =>
        item.id === product.id &&
        item.product_attributes.every(attr =>
          cartItem.product_attributes.some(
            newAttr => newAttr.id === attr.id && newAttr.value === attr.value
          )
        )
      );
  
      try {
        if (existingItem) {
          dispatch(updateItem({
            ...existingItem,
            quantity: existingItem.quantity + quantity,
          }));
          toast.success("Item Updated In Cart Successfully");
        } else {
          dispatch(addItem(cartItem));
          toast.success("Item Added To Cart Successfully");
        }
      } catch (err) {
        toast.error("Something Went Wrong");
        setLoading(false);
      }
    }
  };
  
  
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <NavStrip />
      <BreadCrumb
        page="Product Details"
        from="Shop"
        handleClickProducts={handleClickProducts}
      />
      <Container>
        <Grid container spacing={4} sx={{ mt: 5, pb: 5 }}>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container xs={12} spacing={3}>
              {loading ? (
                <>
                  <LoadingModal open={loading} />
                </>
              ) : (
                <>
                {
                  allProducts.length>0? (
                    allProducts.map((product) => {
                      return (
                        <>
                          <Grid item xs={12} md={6} lg={6}>
                            <img
                              src={product?.images && product?.images[0]?.src}
                              style={{ height: 460, width: "100%" }}
                            />
                            <Stack
                              direction={{ xs: "row", md: "row" }}
                              sx={{ mt: 1 }}
                            >
                              {product?.images?.length > 0 && product?.images?.map((item, index) => (
                                <img
                                  src={item?.src}
                                  style={{
                                    height: 110,
                                    width: 110,
                                    marginRight: 10,
                                  }}
                                />
                              ))}
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <Typography
                              sx={{
                                fontFamily: FONTS.title,
                                color: COLORS.primary,
                              }}
                            >
                              {product?.name}
                            </Typography>
                            <Typography
                              sx={{ fontFamily: FONTS.text, fontSize: 15 }}
                            >
                              {product?.vendor?.shop_name}
                            </Typography>
                            <Grid
                              container
                              sx={{
                                mt: 2,
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <Grid item xs={12} md={12} lg={12}>
                                <Typography
                                  sx={{
                                    fontFamily: FONTS.subtitle2,
                                    fontSize: 20,
                                    color: COLORS.primary,
                                  }}
                                >
                                  ${product?.price}
                                </Typography>
                              </Grid>
                              <Box
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            ml: 0.5,
                          }}
                        >
                          <Typography variant="body2" sx={{ fontSize: 14, mr:0.5, color: "text.secondary" }}>
                            {product.rating_average}
                          </Typography>
                          <Rating
                            name={`rating-${product.id}`}
                            value={product.rating_average}
                            readOnly
                            precision={0.5}
                            sx={{ fontSize: 17, mt:0.2 }}
                          />
                          <Typography variant="body2" sx={{ fontSize: 14,}}>
                            ({product.no_of_reviews})
                          </Typography>
                        </Box>
                              <Grid item xs={12} md={12} lg={12}>
                              <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom:3,
                                marginRight: { md: 2 },
                                marginTop:3
                               
                              }}
                            >
                              <InputLabel
                                sx={{
                                  minWidth: 100,
                                  ml: 1.5,
                                  marginRight: { xs: 0, md: 1 }
                                }}
                              >
                                Length
                              </InputLabel>
                             
                              <FormControl fullWidth>
                                <Select
                                  name="length"
                                  onChange={(e) => handleChange(e, product.id)}
                                >
                                  {product?.attributes?.map((value, index) => (
                                    <MenuItem
                                      key={index}
                                      value={value}
                                    >
                                      {value?.length_inches}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: { xs: 2, md: 0 },
                                marginRight: { md: 2 },
                              }}
                            >
                              <InputLabel
                                sx={{
                                  minWidth: 100,
                                  ml: 1.5,
                                  marginRight: { xs: 0, md: 1 },
                                }}
                              >
                                Color
                              </InputLabel>
                              <FormControl fullWidth>
                                <Select
                                  name="color"
                                  onChange={(e) => handleChange(e, product.id)}
                                >
                                  {product?.attributes?.map((value, index) => (
                                    <MenuItem key={index} value={value}>
                                      {value?.color}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                sx={{
                                  mt: 2,
                                }}
                              >
                                <Stack
                                  direction={{ xs: "row", md: "row", lg: "row" }}
                                  sx={{ alignItems: "center" }}
                                >
                                  <Button
                                    variant="contained"
                                    sx={{
                                      // mx: 0.5,
                                      my: 0.3,
                                      borderRadius: 0,
                                      height: 35,
                                      w: 50,
                                    }}
                                    onClick={handleDecreaseQuantity}
                                  >
                                    -
                                  </Button>
                                  <Typography
                                    sx={{
                                      fontFamily: FONTS.subtitle2,
                                      textAlign: "center",
                                      p: 0.7,
                                      px: 3,
                                      border: 1,
                                      borderColor: COLORS.border,
                                      height: 35,
                                    }}
                                  >
                                    {quantity}
                                  </Typography>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      my: 0.3,
                                      borderRadius: 0,
                                      height: 35,
                                      width: 50,
                                    }}
                                    onClick={handleIncreaseQuantity}
                                  >
                                    +
                                  </Button>
                                </Stack>
                              </Grid>
                            
                              <Grid>
                              <Button
                                variant="contained"
                                sx={{
                                  my: 4,
                                  height: 45,
                                  borderRadius: 0,
                                  background: COLORS.primary,
                                  mr: 2,
                                }}
                                onClick={() => handleAddToCart(product)}
                              >
                                Add To Cart
                              </Button>
                              </Grid>
                              <Grid item xs={12} md={12} lg={12}>
                                  <Typography variant="h5" sx={{ fontFamily: FONTS.text, fontSize: 15, mr: 1, color: COLORS.secondary }}>
                                    Description:
                                  </Typography>
                                  <Typography variant="body2" sx={{ fontFamily: FONTS.text, fontSize: 16 }}>
                                    {product.short_description}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                  <Typography variant="h5" sx={{ fontFamily: FONTS.text, fontSize: 15, mr: 1, color: COLORS.secondary }}>
                                    Care Instructions:
                                  </Typography>
                                  <Typography variant="body2" sx={{ fontFamily: FONTS.text, fontSize: 16, mt: 0.5 }}>
                                    With proper care, this product should last for  
                                     ({product.care_instruction_no})+({product.care_instruction_period})
                                  </Typography>
                                </Grid>
                            </Grid>
                          </Grid>
                        </>
                      );
                    })  
                  ):
                  (
                    <Typography variant="body1" style={{width:"100%",textAlign:"center" ,fontSize:"30px"}}>No data found</Typography>
                  )
                }
                  
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </React.Fragment>
  );
};
export default connect()(Details);
