import { useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import google from "@iconify/icons-eva/google-fill";
import facebook from "@iconify/icons-eva/facebook-fill";

import { toast } from "react-toastify";
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Container,
  Typography,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SignUpModal from "../../modals/signupModal";
import { connect } from "react-redux";
import { getVendorProfile, loginUser } from "../../../redux/actions";
import LoadingModal from "../../modals/LoadingModal";
import { validateEmail } from "../../../services";
import { store } from "../../../redux/store/store";

// ----------------------------------------------------------------------

function LoginForm(props) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const location = useLocation();

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleValidation = () => {
    if (!validateEmail(emailAddress)) {
      setEmailError(true);
      setEmailHelperText("Please enter a valid email address");
      return false;
    } else if (password === "" || password.length < 6) {
      setPasswordError(true);
      setPasswordHelperText("Please enter password greater than 6 characters");
      return false;
    } else {
      return true;
    }
  };
  const handleLoginUser = () => {
    const isValidated = handleValidation();
    if (isValidated) {
      props
        .loginUser(emailAddress, password)
        .then(async () => {
          const { userRoles, loginUserStatus, accessToken } =
            store.getState().authentication;
          console.log(loginUserStatus);
          if (loginUserStatus) {
            await localStorage.setItem("token", accessToken);
            if (userRoles[1] === undefined) {
              const redirectTo = location.state?.from || "/home";
              navigate(redirectTo);
            } else {
              await props
                .getVendorProfile(accessToken)
                .then(() => {})
                .catch((error) => {
                  console.log(error);
                });
              navigate("/dashboard/vendor-dashboard");
            }
            // toast.success("Loged in", {
            //   position: "top-right",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
          }
        })
        .catch((error) => {
          console.log(error.message);
          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
          });
        });
    }
  };
  const { loginUserLoading, vendorProfileLoading } = props.authentication;
  return (
    <Container>
      <Stack spacing={3}>
        <TextField
          error={emailError}
          fullWidth
          autoComplete="username"
          type="email"
          label="Email address"
          onChange={(e) => {
            setEmailAddress(e.target.value);
            setEmailError(false);
            setEmailHelperText("");
          }}
          value={emailAddress}
          helperText={emailHelperText}
        />

        <TextField
          error={passwordError}
          fullWidth
          autoComplete="current-password"
          type={showPassword ? "text" : "password"}
          label="Password"
          onChange={(e) => {
            setPassword(e.target.value);
            setPasswordError(false);
            setPasswordHelperText("");
          }}
          value={password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={handleShowPassword}>
                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          helperText={passwordHelperText}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <FormControlLabel control={<Checkbox />} label="Remember me" />
        <Button
            variant="text"
            sx={{ fontFamily: "PTSans-Bold",  fontSize: 16 }}
            onClick={() => navigate("/forgot")}
          >
            Forgot Password?
          </Button>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        // onClick={() => navigate("/product-categories")}
        onClick={() => handleLoginUser()}
      >
        Sign In
      </LoadingButton>
      <Typography
        variant="body2"
        sx={{
          mt: 3,
          textAlign: "center",
          fontSize: 16,
          color: "text.secondary",
          fontFamily: "PTSans-Regular",
        }}
      >
        Don’t have an account? &nbsp;
        <Button
            variant="text"
            sx={{ fontSize: 16 }}
            onClick={() => navigate("/register-customer")}
          >
            Sign Up
          </Button>
      </Typography>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        sx={{ backgroundColor: "#4267B2", mb: 1, mt: 5 }}
        startIcon={<Icon icon={facebook} />}
      >
        Login With Facebook
      </LoadingButton>
      <Button
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        sx={{ backgroundColor: "#e34242" }}
        startIcon={<Icon icon={google} />}
      >
        Login With Google
      </Button>
      <SignUpModal open={showModal} handleClose={handleCloseModal} />
      <LoadingModal open={loginUserLoading || vendorProfileLoading} />
    </Container>
  );
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
});
const mapDispatchToProps = (dispatch) => ({
  loginUser: (emailAddress, password) =>
    dispatch(loginUser(emailAddress, password)),
  getVendorProfile: (token) => dispatch(getVendorProfile(token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
