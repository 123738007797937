import React, { useState, useEffect } from "react";
import { Avatar, Box, Button, Stack, Typography, Grid, Divider } from "@mui/material";
import { COLORS, ICONS, IMAGES } from "../../constants";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RatingStars } from "../../components";
import { NavStrip } from "../../components/website/Navbar";
import { handlePostApi } from "../../services";
import { BASE_URL } from "../../apis";
import { SHOPPING_CART } from "../../apis";
import { toast } from "react-toastify";
import { FONTS } from "../../constants/theme";
import CircularProgress from "@mui/material/CircularProgress";
import { removeItem, clearCart, updateItem } from "../../redux/Slice/CartSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Checkin = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const cartItems = useSelector((state) => state?.cart?.items);
  const [Productss, setProductss] = useState(cartItems);
  const [loadingbar, setloadingbar] = useState(false);
  const dispatch = useDispatch();

  const lengthAttributeValue = (attributes, attributeId) => {
    const attribute = attributes.find(
      (attr) =>
        attr?.length_attribute_id === attributeId?.id &&
        attr?.length_value_id === attributeId?.value
    );
    return attribute ? attribute.length_inches : null;
  };

  const colorttributeValue = (attributes, attributeId) => {
    const attribute = attributes.find(
      (attr) =>
        attr?.color_attribute_id === attributeId?.id &&
        attr?.color_value_id === attributeId?.value
    );
    return attribute ? attribute.color : null;
  };

  const handleDecreaseQuantity = (data) => {
    setProductss((prevProducts) =>
      prevProducts.map((product) =>
        product.id === data.id
          ? { ...product, quantity: Math.max(1, product.quantity - 1) }
          : product
      )
    );

    const cartItem = {
      ...data,
      quantity:  data.quantity - 1,
      shopping_cart_type: "ShoppingCart",
    };

     dispatch(updateItem(cartItem));
     
  };

  const handleIncreaseQuantity = (data) => {
    let length = data?.attributes.find(
      (attr) =>
        attr?.length_attribute_id === data.product_attributes[0]?.id
    );
    let color = data.attributes.find(
      (attr) =>
        attr?.color_attribute_id === data.product_attributes[1]?.id
    );
    if (data?.quantity >= length.stock || data?.quantity >= color.stock) {
      toast.error("Error: Quantity exceeds stock. Please decrease quantity.");
    } else {
      setProductss((prevProducts) => {
        const updatedProducts = prevProducts.map((product) =>
          product.id === data.id
            ? { ...product, quantity: product.quantity + 1 }
            : product
        );
  
        return updatedProducts;
      });
      const cartItem = {
        ...data,
        quantity:  data.quantity + 1,
        shopping_cart_type: "ShoppingCart",
      };

       dispatch(updateItem(cartItem));

      toast.success("Item Updated In Cart Successfully");
    }
  };

  const handleRemoveItem = (id) => {

    dispatch(removeItem({ id }));
    toast.success("Remove item successfully");
  };

  useEffect(() => {
    setProductss(cartItems);
  }, [cartItems]);

  const handleCheckout = async () => {
    if (!token) {
      toast.info("Please sign in or register to continue");
      navigate("/login", { state: { from: "/cart" } });
      return;
    }

    setloadingbar(true);
    let data = [];

    console.log(Productss, "Products");
    // Constructing the data array from Productss
    for (let i = 0; i < Productss.length; i++) {
      data.push({
        product_id: Productss[i].id,
        quantity: Productss[i].quantity,
        shopping_cart_type: "ShoppingCart",
        product_attributes: Productss[i].product_attributes,
        amount: Productss[i].price,
        shipping_cost: Productss[i]?.vendor?.shipping_cost,
      });
    }

    const jsonData = JSON.stringify(data);
    let url = `${BASE_URL.baseUrl}${SHOPPING_CART.addToCart}`;

    try {
      const response = await handlePostApi(url, jsonData, token);

      if (response.status === 200) {
        const { StripeCheckOutURL } = response.data;
        setloadingbar(false);
        dispatch(clearCart());
        toast.success("Cart Sent Successfully");
        window.open(StripeCheckOutURL, "_blank"); // Open the Stripe URL in a new tab
      } else {
        setloadingbar(false);
        toast.error("Something Went Wrong");
      }
    } catch (err) {
      setloadingbar(false);
      toast.error("Something Went Wrong");
      console.error("Checkout Error:", err); // Log the error for debugging
    }
  };

  // Calculate the order summary values
  const subtotal = Productss.reduce((sum, item) => sum + item.price * item.quantity, 0);

  const uniqueShops = new Set();
  const shipping = Productss.reduce((sum, item) => {
    if (!uniqueShops.has(item.vendor.shop_name)) {
      uniqueShops.add(item.vendor.shop_name);
      return sum + (item?.vendor?.shipping_cost || 0);
    }
    return sum;
  }, 0);

  const total = subtotal + shipping;

  return (
    <>
      <NavStrip />
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            {Productss.length > 0 ? (
              Productss.map((data) => {
                return (
                  <Box sx={{ my: 2 }} key={data.id}>
                    <Grid container spacing={2} sx={{ boxShadow: 2, marginTop: 4 }}>
                      <Grid item xs={12} md={6} lg={6}>
                        <Avatar
                          variant="square"
                          src={data?.images[0]?.src}
                          sx={{ height: 310, width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <Stack
                          sx={{
                            justifyContent: "space-between",
                            width: "100%",
                            p: 2,
                          }}
                        >
                          <Stack
                            direction={{ xs: "row", sm: "row" }}
                            sx={{
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ fontFamily: "PTSans-Bold", fontSize: 22 }}
                            >
                              {data?.name}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={{ xs: "row", sm: "row" }}
                            sx={{ alignItems: "center" }}
                          >
                            <RatingStars
                              ratingValue={data?.rating_average}
                              size={22}
                              my={1}
                            />
                            <Typography
                              sx={{ fontSize: 12, fontFamily: "PTSans-Bold" }}
                            >
                              ({data?.no_of_reviews})
                            </Typography>
                          </Stack>
                          <Stack direction={{ xs: "row", sm: "row" }} sx={{ my: 2 }}>
                            <StoreMallDirectoryIcon
                              sx={{ color: COLORS.secondary }}
                            />
                            <Typography
                              sx={{
                                color: COLORS.grey,
                                ml: 2,
                                fontFamily: "PTSans-Regular",
                              }}
                            >
                              {data?.vendor?.shop_name}
                            </Typography>
                          </Stack>
                          <Stack direction={{ xs: "row", sm: "row" }} sx={{ my: 1 }}>
                            <Stack sx={{ width: 100 }}>
                              <Typography
                                sx={{
                                  color: COLORS.grey,
                                  mr: 2,
                                  fontFamily: "PTSans-Regular",
                                }}
                              >
                                Color:
                              </Typography>
                            </Stack>
                            <Typography
                              sx={{
                                color: COLORS.black,
                                fontFamily: "PTSans-Bold",
                              }}
                            >
                              {colorttributeValue(
                                data?.attributes,
                                data?.product_attributes[1]
                              )}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={{ xs: "row", sm: "row" }}
                            sx={{ justifyContent: "space-between" }}
                          >
                            <Stack
                              direction={{ xs: "row", sm: "row" }}
                              sx={{ width: "50%" }}
                            >
                              <Stack sx={{ width: 100 }}>
                                <Typography
                                  sx={{
                                    color: COLORS.grey,
                                    mr: 2,
                                    fontFamily: "PTSans-Regular",
                                  }}
                                >
                                  Length:
                                </Typography>
                              </Stack>
                              <Typography
                                sx={{
                                  color: COLORS.black,
                                  fontFamily: "PTSans-Bold",
                                }}
                              >
                                {lengthAttributeValue(
                                  data?.attributes,
                                  data?.product_attributes[0]
                                )}
                              </Typography>
                            </Stack>

                            <Stack
                              direction={{ xs: "row", sm: "row" }}
                              sx={{ width: "50%", alignItems: "center", justifyContent: "flex-end" }}
                            >
                              <Stack sx={{ width: 100 }}>
                                <Typography
                                  sx={{
                                    color: COLORS.grey,
                                    mr: 2,
                                    fontFamily: "PTSans-Regular",
                                  }}
                                >
                                  Price:
                                </Typography>
                              </Stack>
                              <Typography
                                sx={{
                                  color: COLORS.secondary,
                                  fontFamily: "PTSans-Bold",
                                  fontSize: 20,
                                }}
                              >
                                ${data?.price * data?.quantity}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack direction={{ xs: "row", sm: "row" }} sx={{ my: 1 }}>
                            <Stack sx={{ width: 100 }}>
                              <Typography
                                sx={{
                                  color: COLORS.grey,
                                  mr: 2,
                                  fontFamily: "PTSans-Regular",
                                }}
                              >
                                Quantity
                              </Typography>
                            </Stack>
                            <Button
                              variant="contained"
                              sx={{
                                borderRadius: 0,
                                height: 35,
                                width: 35,
                              }}
                              onClick={() => handleDecreaseQuantity(data)}
                            >
                              -
                            </Button>
                            <Typography
                              sx={{
                                fontFamily: FONTS.subtitle2,
                                textAlign: "center",
                                p: 0.7,
                                px: 3,
                                border: 1,
                                borderColor: COLORS.border,
                                height: 35,
                              }}
                            >
                              {data.quantity}
                            </Typography>
                            <Button
                              variant="contained"
                              sx={{
                                borderRadius: 0,
                                height: 35,
                                width: 35,
                              }}
                              onClick={() => handleIncreaseQuantity(data)}
                            >
                              +
                            </Button>
                          </Stack>
                          <Stack
                            sx={{
                              width: "100%",
                              display: "flex !important",
                              alignItems: "flex-start",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="warning"
                              onClick={() => handleRemoveItem(data.id)}
                              sx={{
                                borderRadius: 0,
                                height: 50,
                                width: 150,
                              }}
                            >
                              Remove Item
                            </Button>
                            <Stack
                              direction={{ xs: "row", sm: "row" }}
                              sx={{ width: "100%", alignItems: "center", justifyContent: "flex-end" }}
                            >
                              <Stack sx={{ width: 100 }}>
                                <Typography
                                  sx={{
                                    color: COLORS.black,
                                    mr: 2,
                                    fontFamily: "PTSans-Regular",
                                  }}
                                >
                                  Shipping:
                                </Typography>
                              </Stack>
                              <Typography
                                sx={{
                                  color: COLORS.black,
                                  fontFamily: "PTSans-Regular",
                                  fontSize: 20,
                                }}
                              >
                                ${data?.vendor?.shipping_cost}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                );
              })
            ) : (
              <>
                <Typography
                  variant="body1"
                  style={{ width: "100%", textAlign: "center", fontSize: "30px" }}
                >
                  No data found
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            {Productss.length > 0 && (
              <>
                <Box sx={{ my: 4, p: 2, boxShadow: 2 }}>
                  <Typography
                    sx={{ fontFamily: "PTSans-Bold", fontSize: 22, mb: 2 }}
                  >
                    Order Summary
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ mb: 1 }}
                  >
                    <Typography sx={{ fontFamily: "PTSans-Regular", fontSize: 18 }}>
                      Subtotal
                    </Typography>
                    <Typography sx={{ fontFamily: "PTSans-Bold", fontSize: 18 }}>
                      ${subtotal.toFixed(2)}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ mb: 1 }}
                  >
                    <Typography sx={{ fontFamily: "PTSans-Regular", fontSize: 18 }}>
                      Shipping
                    </Typography>
                    <Typography sx={{ fontFamily: "PTSans-Bold", fontSize: 18 }}>
                      ${shipping.toFixed(2)}
                    </Typography>
                  </Stack>
                  <Divider sx={{ my: 2 }} />
                  <Stack direction="row" justifyContent="space-between">
                    <Typography sx={{ fontFamily: "PTSans-Regular", fontSize: 18 }}>
                      Total
                    </Typography>
                    <Typography sx={{ fontFamily: "PTSans-Bold", fontSize: 18 }}>
                      ${total.toFixed(2)}
                    </Typography>
                  </Stack>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    sx={{
                      mt: 0,
                      borderRadius: 0,
                      margin: "40px auto",
                      padding: "10px 0px",
                      width: "200px",
                    }}
                    onClick={handleCheckout}
                  >
                    {loadingbar ? (
                      <CircularProgress size={25} sx={{ color: "white" }} />
                    ) : (
                      "Checkout"
                    )}
                  </Button>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Checkin;
