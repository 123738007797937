import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { COLORS, FONTS } from "../../constants";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export default function CardView({ title, description, withButton }) {
  return (
    <Card sx={{ minWidth: 275, borderRadius: 0 }}>
      <CardContent>
        <Typography
          sx={{
            fontSize: 24,
            color: COLORS.primary,
            fontFamily: FONTS.title,
          }}
          gutterBottom
        >
          {title}
        </Typography>
        {/* <Typography variant="h5" component="div">
          be{bull}nev{bull}o{bull}lent
        </Typography> */}
        {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
          adjective
        </Typography> */}
        <Typography variant="body2">{description}</Typography>
      </CardContent>
      {withButton && (
        <CardActions>
          <Button size="small">Learn More</Button>
        </CardActions>
      )}
    </Card>
  );
}
