import { Box, Button, Container, Grid, Link, Typography } from "@mui/material";
import { COLORS, FONTS, ICONS, IMAGES } from "../../constants";
function Copyright(props) {
  return (
    <Typography variant="body2" color={COLORS.white} align="center" {...props}>
      {" © " + new Date().getFullYear() + " "}
      <Link
        className="link"
        color={COLORS.primary}
        sx={{ textDecoration: "none" }}
        href="https://www.hairscoutapp.com/"
      >
        Hair Scout
      </Link>{" "}
      {" all right reserved"}
      {"."}
    </Typography>
  );
}
const footers = [
  {
    title: "Support",
    screens: [
      { name: "Contact Us", link: "mailto:contact@hairscout.com" },
    ],
  },
  {
    title: "Information",
    screens: [
      { name: "User Agreement", link: "./user-agreement" },
      { name: "Privacy Policy", link: "./privacy-policy" },
      { name: "Apply to become a brand", link: "https://forms.gle/wsg4gzk1sVgAn3kS8", target: "_blank" },
    ],
  },
];
function Footer() {
  return (
    <Container
      maxWidth="100%"
      sx={{
        py: [3, 6],
        background: COLORS.secondary,
        mt: 5,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        spacing={5}
        justifyContent="space-between"

      >
        <Grid item xs={12} sm={6} md={3}>
          <img
            src={IMAGES.logo}
            alt="logo"
            style={{ height: 60, marginTop: 10 }}
          />

          <Typography
            sx={{
              mt: 3,
              fontSize: 17,
              color: COLORS.white,
              fontFamily: FONTS.subtitle,
            }}
          >
            Connecting shoppers with their perfect brands, and brands with their
            perfect customers.
          </Typography>
        </Grid>
        {footers.map((footer) => (
          <Grid item xs={12} sm={6} md={3} key={footer.title}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontFamily: FONTS.title,
              }}
            >
              {footer?.title}
            </Typography>
            <ul>
              {footer?.screens?.map((item) => (
                <li key={item.name}>
                  <Link
                    className="link"
                    href={item?.link}
                    target={item.name === "Apply to become a brand" ? "_blank" : "_self"}
                    rel={item.name === "Apply to become a brand" ? "noopener noreferrer" : ""}
                    sx={{
                      textDecoration: "none",
                      fontFamily: FONTS.subtitle,
                      lineHeight: 2.2,
                      color: COLORS.white,
                      fontSize: 16,
                    }}
                  >
                    {item?.name}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} sm={6} md={3} >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontFamily: "PlayFairDisplay-Bold",
            color: COLORS.primary,
            textAlign: "center",
            mt: 6,
            fontSize: 20,
          }}
        >
          Follow us and stay tuned
        </Typography>
        <Grid
          container
          sx={{
            alignItems: "center",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <Grid item>
            <Box
              sx={{
                "&:hover": {
                  backgroundColor: COLORS.other,
                },
                display: "flex",
                ml: 2,
                height: 40,
                width: 40,
                borderRadius: 20,
                backgroundColor: COLORS.backgroundDark,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a href="https://www.facebook.com/share/Nbtp8e2DfNhddRWd/?mibextid=LQQJ4d">
                <img src={ICONS.facebook} alt="logo" style={{ height: 20 }} />
              </a>
            </Box>
          </Grid>
          <Grid item>
            <Box
              sx={{
                "&:hover": {
                  backgroundColor: COLORS.other,
                },
                display: "flex",
                ml: 3,
                height: 40,
                width: 40,
                borderRadius: 20,
                backgroundColor: COLORS.backgroundDark,
                justifyContent: "center",
                alignItems: "center",
                mx: 4,
              }}
            >
              <a href="https://www.instagram.com/hairscoutapp/">
                <img src={ICONS.instagram} alt="logo" style={{ height: 20 }} />
              </a>

            </Box>
          </Grid>
        </Grid>

      </Grid>
      <Copyright
        sx={{
          mt: 5,
          fontFamily: FONTS.subtitle,
          borderTop: 1,
          borderColor: COLORS.other,
          pt: 3,
        }}
      />
    </Container>
  );
}

export default Footer;
